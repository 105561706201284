<template>
  <div class="container border-div">
    <div class="columns">
      <div class="column content section">
        <div class="columns is-centered">
          <div class="column is-half">
            <h3 class="has-text-centered">
              Submit an Invoice
            </h3>
            The invoicing portal helps the City streamline the invoice-to-payment process. Please use this form to submit your invoice to the appropriate City department. You can also track payments and view payment history through the
            <router-link
              to="/vendor"
            >
              Payments
            </router-link> menu. Beginning July 1, 2021, all vendors with City contracts will be required to submit invoices electronically.
            <br><br>

            <h4>
              Before you start
            </h4>
            Before you submit an invoice, be sure to:
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://rev.phila.gov/taxcompliance/"
                >Verify your tax status</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://epay.phila.gov/paymentcenter/accountlookup/"
                >Pay past due taxes</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.phila.gov/services/payments-assistance-taxes/enroll-in-direct-deposit-as-a-vendor-or-a-foster-parent/"
                >Enroll in electronic payments</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/Vendor_Payment_Checklist_January-2021-FINALIZED_182021.pdf"
                >Complete your invoice checklist (PDF)</a>
              </li>
            </ul>

            <h4>
              Payment terms
            </h4>
            <ul>
              <li>The City will only process properly submitted invoices.</li>
              <li>Incorrectly submitted invoices will result in processing and payment delays.</li>
              <li>If you have past due taxes, payments will be held.</li>
            </ul>

            <h4>
              Questions
            </h4>
            Please contact <a href="mailto:vendorinvoice.questions@phila.gov">vendorinvoice.questions@phila.gov</a> with any questions about submitting an invoice or using the new invoice portal.
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-half">
            <ValidationObserver
              v-slot="validation"
              tag="div"
            >
              <input-form :errors="validation.errors">
                <div class="columns">
                  <div class="column is-full">

                    <div class="field">
                      <div
                        class="top-align label radio-desc required"
                      >
                        Invoice type*
                      </div>
                      <font-awesome-icon
                        :id="'invoice-icon'"
                        icon="info-circle"
                        class="fa-infoCircle icon-margin"
                        @click="infoClick('invoice')"
                      />
                      <div
                        v-if="infoDivs.invoice"
                        class="is-field-info-important field-info"
                      >
                        Select the type of invoice you are submitting. If you are unsure, <a
                          target="_blank"
                          href="/departments/department-finance"
                        >contact the City department.</a>
                      </div>

                      <div class="control">
                        <vee-radio
                          v-model="invoiceType"
                          :options="invoiceTypeOptions"
                          title="Invoice type"
                          name="Invoice Type"
                          rules="required"
                        />
                      </div>
                    </div>

                    <div
                      v-if="invoiceType === 'other'"
                      class="field"
                    >
                      <div class="control">
                        <vee-textbox
                          v-model="invoiceTypeOther"
                          name="Other Invoice Type"
                          placeholder="Invoice Type"
                          rules="required"
                          required
                        />
                      </div>
                    </div>

                    <div class="field">
                      <div
                        class="top-align label radio-desc required"
                      >
                        Is this invoice part of a contract?*
                      </div>

                      <font-awesome-icon
                        :id="'contract-icon'"
                        icon="info-circle"
                        class="fa-infoCircle icon-margin"
                        @click="infoClick('contract')"
                      />
                      <div
                        v-if="infoDivs.contract"
                        class="is-field-info-important field-info"
                      >
                        If yes, you'll need the contract number.
                      </div>

                      <div class="control">
                        <vee-radio
                          v-model="isContract"
                          :options="{ 'yes': 'Yes', 'no': 'No' }"
                          title="Part of a contract"
                          name="Is Contract?"
                          rules="required"
                        />
                      </div>
                    </div>

                    <div
                      v-if="isContract === 'yes'"
                      class="field"
                    >
                      <div class="control">
                        <vee-textbox
                          v-model="contractNumber"
                          rules="required"
                          name="Contract Number"
                          placeholder="Contract number"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <div class="top-align label required">
                        Department*
                      </div>

                      <font-awesome-icon
                        :id="'contract-icon'"
                        icon="info-circle"
                        class="fa-infoCircle icon-margin"
                        @click="infoClick('department')"
                      />
                      <div
                        v-if="infoDivs.department"
                        class="is-field-info-important field-info"
                      >
                        Select the City department you're invoicing. If it has units, a list will appear. Some departments and units have <a
                          target="_blank"
                          href="/Dept_specific_vendor_instructions_FINALIZED_1-8-21.pdf"
                        >special instructions (PDF).</a>
                      </div>
                      <div class="control">
                        <vee-dropdown
                          v-model="department"
                          name="department"
                          placeholder="Department"
                          value-key="id"
                          text-key="name"
                          :options="departments"
                          rules="required"
                        />
                      </div>
                    </div>
                    <div
                      v-if="units && units.length"
                      class="field"
                    >
                      <div class="top-align label required">
                        Unit*
                      </div>
                      <div class="control">
                        <vee-dropdown
                          v-model="unit"
                          name="unitName"
                          placeholder="Unit"
                          value-key="id"
                          text-key="unitName"
                          :options="units"
                          rules="required"
                        />
                      </div>
                    </div>

                    <div class="field">
                      <p class="label m-b-none">
                        Upload invoice*
                      </p>
                      <div class="control">
                        <div class="field">
                          <div class="control">
                            <vee-file
                              ref="attach-support"
                              v-model="invoice"
                              name="Invoice Upload"
                              :rules="{ required: true, ext: [ 'pdf', 'jpg', 'jpeg', 'png' ] }"
                            >
                              <p><small>The allowed file extensions are pdf, jpg, jpeg, and png.</small></p>
                            </vee-file>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <p class="m-b-none">
                        <span class="label">Upload supporting documents</span>You must include a completed <a
                          target="_blank"
                          href="/Vendor_Payment_Checklist_January-2021-FINALIZED_182021.pdf"
                        >invoice checklist (PDF)</a>. If applicable, you must attach a current price list. You may also attach other supporting documents, as described in the invoice checklist.
                      </p>
                      <div class="control">
                        <div
                          v-for="(file, index) in supportingDocs"
                          :key="index"
                          class="field"
                        >
                          <div
                            :class="'control ' + index"
                          >
                            <vee-file
                              ref="attach-support"
                              v-model="supportingDocs[index]"
                              placeholder="Attach supporting documents"
                              :rules="{ ext: [ 'pdf', 'jpg', 'jpeg', 'png' ] }"
                              :name="'supportFile_' + index+1"
                              title="Choose file"
                              :remove-button="supportingDocs.length > 1"
                              @delete-doc="deleteDoc(index)"
                            >
                              <p><small>The allowed file extensions are pdf, jpg, jpeg, and png.</small></p>
                            </vee-file>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="shouldShowAddDocs"
                      class="input-wrap field is-grouped is-grouped-right"
                    >
                      <div class="control">
                        <button
                          type="button"
                          class="button is-primary is-outlined is-small"
                          @click.prevent="supportingDocs.push(null)"
                        >
                          <span class="icon"><i class="fas fa-plus" /></span>
                          <span>Add file</span>
                        </button>
                      </div>
                    </div>

                    <div class="control">
                      <div class="field">
                        <div class="control has-text-centered">
                          <button
                            class="button is-primary"
                            :class="{ 'is-loading': isLoading }"
                            @click.prevent="submitForm(validation)"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </input-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '../../plugins/bus';

import File from "@/components/common/File";
import { objectToFormData } from 'object-to-formdata';

import InputForm from '@/components/InputForm';

import {
  Textbox,
  Dropdown,
  Radio,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email, ext } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);
const VeeDropdown = withValidation(Dropdown);
const VeeRadio = withValidation(Radio);
const VeeFile = withValidation(File);

export default {
  name: "InvoicesForm",
  components: {
    // IconToolTipWithValidation,
    VeeTextbox,
    VeeDropdown,
    VeeRadio,
    VeeFile,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      vendorName: this.$store.state.login.loggedIn.firstName + ' ' + this.$store.state.login.loggedIn.lastName,
      vendorEmail: this.$store.state.login.loggedIn.email,
      invoiceType: null,
      invoiceTypeOptions: {
        'purchase-order-invoice': 'Purchase order',
        'professional-services-invoice': 'Professional services',
        'public-works-invoice': 'Public Works',
        // 'unknown': 'Unknown',
        'other': 'Other, such as a micro purchase, small order purchase, direct payment, or petty cash (please specify)',
      },
      invoiceTypeOther: null,
      isContract: null,
      contractNumber: null,
      department: '',
      unit: null,
      units: null,
      invoice: null,
      supportingDocs: [ null ],
      infoDivs: {
        invoice: false,
        contract: false,
        department: false,
      },
    };
  },
  computed: {
    shouldShowAddDocs() {
      let value = true;
      // for (let value of this.supportingDocs)
      if (this.supportingDocs.includes(null)) {
        console.log('shouldShowAddDocs there is a null');
        value = false;
      }
      return value;
    },
    departments() {
      let values;
      if (this.$store.state.departments.departments) {
        values = this.$store.state.departments.departments.filter(x => x.hasInvoiceContactData);
      }
      return values;
    },
  },
  watch: {
    department(nextDepartment) {
      this.getUnits(nextDepartment);
    },
  },
  async fetch({ store }) {
    await store.dispatch('departments/getAllDepartments');
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
    extend('ext', {
      ...ext,
    });
  },
  mounted() {
    this.department = this.departments[0].id;
  },
  methods: {
    infoClick(divName) {
      // console.log('Form.vue infoClick, test:', divName, 'this.infoDivs[divName]:', this.infoDivs[divName]);
      if (Object.keys(this.infoDivs).includes(divName)) {
        this.infoDivs[divName] = !this.infoDivs[divName];
      }
    },
    deleteDoc(index) {
      // console.log('Form.vue deleteDoc is running, index:', index);
      if (index != 0) {
        this.$data.supportingDocs.splice(index,1);
      } else {
        if (this.$data.supportingDocs.length > 1) {
          this.$data.supportingDocs.splice(index,1);
        } else {
          this.$data.supportingDocs.splice(index,1);
          this.$data.supportingDocs[0] = null;
        }
      }
      EventBus.$emit('file-deleted');
    },
    async getUnits(department) {
      try {
        const departmentIndex = this.$store.state.departments.departments.findIndex(d => d.id === department);
        if (this.$store.state.departments.departments[departmentIndex].units) {
          this.units = this.$store.state.departments.departments[departmentIndex].units.filter(x => x.hasInvoiceContactData);
          if (this.units.length) {
            this.unit = this.units[0].id;
          } else {
            this.unit = null;
          }
        } else {
          this.units = [];
          this.unit = null;
        }
      } catch (err) {
        this.units = [];
        this.unit = null;
        // hard coding fake units
      }
    },
    resetForm() {
      const data = this.$options.data();
      Object.assign(this.data, data);
    },
    async submitForm(validation) {

      const isValid = await validation.validate();
      if (!isValid) {
        console.log('validation failed');
        return;
      }

      if (!this.invoice) {
        console.log('no invoice');
        return;
      }

      let totalSize = this.invoice.size;
      if (this.supportingDocs[0] != null) {
        this.supportingDocs.forEach(file => {
          if (file) {
            totalSize += file.size;
          }
        });
      }

      if (totalSize > 25000000) {
        this.$modal.show('dialog', {
          title: 'Attachments are too large',
          text: `The total size of your attachments must be lower than 25MB.
          Please remove an attachment or compress your files.`,
        });
        return;
      }

      // const isValid = await this.$refs['invoices-form'].validate();
      // const isValid = await validation.validate();
      // if (!isValid) {
      //   return;
      // }
      this.isLoading = true;

      let unitId, invoiceType;
      if (this.unit) {
        unitId = this.unit;
      }

      if (this.invoiceType === 'other') {
        invoiceType = this.invoiceTypeOther;
      } else {
        invoiceType = this.invoiceType;
      }
      let formData = {
        vendorName: this.vendorName,
        vendorEmail: this.vendorEmail,
        invoiceType: invoiceType,
        contractNum: this.contractNumber || "1",
        departmentId: this.department,
        unitId: unitId,
        primaryInvoice: this.invoice,
      };
      formData = objectToFormData(formData);

      this.supportingDocs.forEach(file => {
        if (typeof file === 'object') {
          formData.append('supportingFiles', file);
        }
      });

      const data = await this.$store.dispatch('invoices/submitInvoice', formData);
      if (data.status == 200) {
        // this.$success('Success!');
        this.isLoading = false;
        this.$router.push('confirmation');
      } else {
        this.isLoading = false;
        this.$warning('Please, try again.');
      }
    },
  },
};
</script>

<style>

.top-align {
  vertical-align: top;
  display: inline-block;
}

.icon-margin {
  margin-left: .5rem !important;
}

.field-info {
  margin-bottom: .5rem !important;
}

</style>
