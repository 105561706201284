<template>
  <div
    class="input-wrap input-file"
    :class="fileClasses"
  >
    <template v-if="error">
      <div class="input-error-msg">
        <span class="icon"><i class="fas fa-exclamation-circle" /></span>
        <span>{{ error }}</span>
      </div>
    </template>
    <div
      :class="{ 'disabled': disabled, 'is-boxed': isBoxed }"
      class="file has-name is-fullwidth is-primary"
    >
      <label class="file-label">
        <input
          v-if="uploadReady"
          ref="file"
          class="file-input"
          type="file"
          :aria-placeholder="placeholder"
          :placeholder="placeholder"
          :disabled="disabled"
          :name="name"
          v-on="inputListeners"
        >
        <span class="file-cta">
          <span class="file-icon">
            <i
              class="fas fa-upload upload-button"
              aria-hidden="true"
            />
          </span>
          <span
            class="file-label"
            v-text="$attrs.title || 'Choose file'"
          />
        </span>
        <span class="file-name">
          {{ !value ? placeholder : value.name }}
        </span>
      </label>
    </div>
    <div class="info">
      <slot />
    </div>
    <div
      v-if="shouldShowRemoveButton"
      class="field is-grouped is-grouped-right"
    >
      <button
        type="button"
        class="button is-outlined is-small is-danger"
        @click.prevent="deleteDoc"
      >
        <span class="icon"><i class="fas fa-trash" /></span>
        <span>Remove</span>
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/bus.js';
import { inputMixins } from '@phila/phila-ui/src/utils/inputMixins';

export default {
  name: 'File',
  mixins: [
    inputMixins,
  ],
  props: {
    isBoxed: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [ Object, File ],
      required: false,
      default: null,
    },
    disabled: {
      type: [ String, Boolean ],
      default: false,
    },
    placeholder:  {
      type: String,
      default: 'No file chosen',
    },
    removeButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadReady: true,
      localValue: this.value,
    };
  },
  computed: {
    fileClasses () {
      return this.classes;
    },
    inputListeners: function () {

      delete this.$listeners['input'];
      delete this.$listeners['blur'];

      var vm = this;
      return Object.assign({},
        this.$listeners,
        {
          change: function (event) {

            // console.log('File.vue change listener is running, event:', event);
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
              return;
            }

            //IE11 needs the change event to be emitted as it does not listen to input
            vm.$emit('change', files[0]);

            //VeeValidate needs the input event to be emitted.
            vm.$emit('input', files[0]);
          },
        },
        {

        }
      );
    },
    shouldShowRemoveButton() {
      let value;
      // if (this.value && this.removeButton) {
      if (this.removeButton) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
  },
  watch: {
    value(newValue) {
      // console.log('File.vue watch value firing, newValue:', newValue);
      if (!newValue) {
        this.clear();
      } else {
        this.$emit('change', newValue);
        this.$emit('input', newValue);
      }
    },
  },
  methods: {
    clear () {
      this.$refs.file.value = '';
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    deleteDoc(e) {
      // console.log('deleteDoc is running, e:', e);
      this.$emit('change');
      this.$emit('input');
      this.$emit('delete-doc');
    },
  },
};
</script>

<style lang="scss" scoped>

  .input-file {
    padding-bottom: 0.5rem;
  }

  .is-boxed {
    .file-name, .file-cta {
      text-align: center;
    }
  }
  .simple {
    .file-cta {
      .file-icon {
        width: auto;
        margin: 0;
      }
      .file-label {
        display: none;
      }
    }
  }
  .disabled {
    * {
      cursor: not-allowed !important;
    }
  }
  .info {
    p, small {
      line-height: 115%;
    }
  }
  .file-name {
    background-color: #fff;
  }
</style>
